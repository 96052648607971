import { createRouter, createWebHistory } from "vue-router";
// import SignIn from "../views/SignIn.vue";
// import Dashboard from "../views/Dashboard.vue";
import Cover from "../views/A&PCover.vue";
import Order from "../views/Order.vue";
import Checkout from "../views/Checkout.vue";
// import ForgotPasscode from "../views/ForgotPasscode.vue";
// import EnterOneTimePasscode from "../views/EnterOneTimePasscode.vue";
// import ResetToInitialPasscode from "../views/ResetToInitialPasscode.vue";
// import ChangePasscode from "../views/ChangePasscode.vue";
import Category from "../views/Category.vue";
import SelectPromoCode from "../views/SelectPromoCode.vue";
import PaymentSuccess from "../views/PaymentSuccess.vue";
import MyReward from "../views/MyReward.vue";
import MyCart from "../views/MyCart.vue";
import Profile from "../views/Profile.vue";
import OrderHistory from "../views/OrderHistory.vue";
import checkUrlExpiry from './middleware';
import OrderLocked from "../views/OrderLocked.vue";
import Error404 from "../views/Error404.vue";
import PageExpired from "../views/PageExpired.vue";
import CompletedOrderHistoryForPayFirst from "../views/CompletedOrderHistoryForPayFirst.vue";

const routes = [
  {
    path: "/:merchant_code/:type?/:branch_code/:table_code?",
    name: "OnlineOrdering",
    component: Cover,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkUrlExpiry
  },
  {
    path: "/:merchant_code/:type?/:branch_code/:table_code?/order",
    name: "Order",
    component: Order,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkUrlExpiry
  },
  {
    path: "/:merchant_code/:type?/:branch_code/:table_code?/check-out",
    name: "CheckOut",
    component: Checkout,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkUrlExpiry
  },
  // {
  //   path: "/:merchant_code/:type?/:branch_code/:table_code?/sign-in/:invite_code?",
  //   name: "SignIn",
  //   component: SignIn,
  //   meta: {
  //     requiresAuth: false
  //   }
  // },
  {
    path: "/:merchant_code/:type?/:branch_code/:table_code?/category",
    name: "Category",
    component: Category,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkUrlExpiry
  },
  {
    path: "/:merchant_code/:type?/:branch_code/:table_code?/select-promo-code",
    name: "SelectPromoCode",
    component: SelectPromoCode,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkUrlExpiry
  },
  {
    path: "/:merchant_code/:type?/:branch_code/:table_code?/payment-success/:order_id/:type_order?",
    name: "PaymentSuccess",
    component: PaymentSuccess,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkUrlExpiry
  },
  {
    path: "/:merchant_code/:type?/:branch_code/:table_code?/my-reward",
    name: "MyReward",
    component: MyReward,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkUrlExpiry
  },
  {
    path: "/:merchant_code/:type?/:branch_code/:table_code?/my-cart",
    name: "MyCart",
    component: MyCart,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkUrlExpiry
  },
  {
    path: "/:merchant_code/:type?/:branch_code/:table_code?/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkUrlExpiry
  },
  {
    path: "/:merchant_code/:type?/:branch_code/:table_code?/order-history",
    name: "OrderHistory",
    component: OrderHistory,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkUrlExpiry
  },
  {
    path: "/:merchant_code/:type?/:branch_code/:table_code?/order-locked/:order_id",
    name: "OrderLocked",
    component: OrderLocked,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkUrlExpiry
  },
  {
    path: "/404",
    name: "Error404",
    component: Error404,
  },
  {
    path: "/page-expired",
    name: "PageExpired",
    component: PageExpired,
  },
  {
    path: "/:merchant_code/:type?/:branch_code/:table_code?/completed-order-history-for-pay-first",
    name: "CompletedOrderHistoryForPayFirst",
    component: CompletedOrderHistoryForPayFirst,
    meta: {
      requiresAuth: false
    },
    beforeEnter: checkUrlExpiry
  },
  // {
  //   path: "/:merchant_code/:type?/:branch_code/:table_code?/forgot-passcode",
  //   name: "ForgotPasscode",
  //   component: ForgotPasscode,
  //   meta: {
  //     requiresAuth: false
  //   }
  // },
  // {
  //   path: "/:merchant_code/:type?/:branch_code/:table_code?/enter-one-time-passcode",
  //   name: "EnterOneTimePasscode",
  //   component: EnterOneTimePasscode,
  //   meta: {
  //     requiresAuth: false
  //   }
  // },
  // {
  //   path: "/:merchant_code/:type?/:branch_code/:table_code?/reset-to-initial-passcode",
  //   name: "ResetToInitialPasscode",
  //   component: ResetToInitialPasscode,
  //   meta: {
  //     requiresAuth: false
  //   }
  // },
  // {
  //   path: "/:merchant_code/:type?/:branch_code/:table_code?/change-passcode",
  //   name: "ChangePasscode",
  //   component: ChangePasscode,
  //   meta: {
  //     requiresAuth: false
  //   }
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to) => {
  localStorage.setItem('MERCHANT_CODE', to.params.merchant_code ?? '')
  localStorage.setItem('BRANCH_CODE', to.params.branch_code ?? '')
})

export default router;
