import api from './api';

class OrderService {
  getListCategory(dataForm) {
    return api.post('/order/get-categories', dataForm);
  }

  searchItem(dataForm) {
    return api.post('/order/search-item', dataForm);
  }

  saveOrder(dataForm) {
    return api.post('/order/save-order', dataForm);
  }

  saveOrderQR(dataForm) {
    return api.post('/order/save-order-qr', dataForm);
  }
  
  checkoutSessionStripe(dataForm) {
    return api.post('/checkout-session-stripe', dataForm);
  }

  saveTransactionByOrder(dataForm) {
    return api.post('/order/save-transaction-by-order', dataForm);
  }

  getItemDetail(dataForm) {
    return api.post('/order/item-detail', dataForm);
  }

  getSlidesHomePage(dataForm) {
    if(dataForm.isMintyApi) {
      // return api.post(`${process.env.VUE_APP_BASE_MINTY_URL}/member/slides`,dataForm);
      return api.post('/slides', dataForm);
    }
  }

  checkAvailableOrdering(dataForm) {
    return api.post('/order/check-available-ordering', dataForm);
  }

  getOrderByOrderId(dataForm) {
    return api.post('/get-order-by-order-id', dataForm);
  }

  checkItemsSelectedStock(dataForm) {
    return api.post('/order/check-items-selected-stock', dataForm);
  }

  getOrdersByMember(branch_code, status, page) {
    return api.get('/get-orders-by-member/' + branch_code + '/' + status + '/' + page);
  }

  getOrdersByNonMember(dataForm) {
    return api.post('/get-orders-by-non-member', dataForm);
  }

  getOrdersByTable(dataForm) {
    return api.post('/get-orders-by-table', dataForm);
  }

  reload(dataForm) {
    return api.post('/order/reload', dataForm);
  }

  retryOrder(dataForm) {
    return api.post('/retry-order', dataForm);
  }

  deleteItemInOrderFailed(dataForm) {
    return api.post('/delete-item-in-order-failed', dataForm);
  }

  getOrderStatus(dataForm) {
    return api.post('/get-order-status', dataForm);
  }

  getSlidesLandingPage(dataForm) {
    if(dataForm.isMintyApi) {
      return api.post(`${process.env.VUE_APP_BASE_MINTY_URL}/member/slides-for-moo`,dataForm);
      // return api.post('/slides-for-moo', dataForm);
    }
  }

  checkbranchStatus(dataForm) {
    return api.post('/check-branch-status', dataForm);
  }

  retryOrderPayLater(dataForm) {
    return api.post('/retry-order-paylater', dataForm);
  }

  requestActionRequired(dataForm) {
    return api.post('/request-action-required', dataForm);
  }

  getOrderByOrderIdForActionRequired(dataForm) {
    return api.post('/get-order-by-order-id-for-action-required', dataForm);
  }

  closeOrderAfterCheckedWasTenderOnPos(dataForm) {
    return api.post('/close-order-after-checked-was-tender-on-pos', dataForm);
  }

  handleRefundOrder(dataForm) {
    return api.post('/handle-refund-order', dataForm);
  }

  saveTransactionByOrderForPayLater(dataForm) {
    return api.post('/order/save-transaction-by-order-for-paylater', dataForm);
  }

  saveTransactionByOrderAfterPayStripe(dataForm) {
    return api.post('/order/save-transaction-by-order-after-pay-stripe', dataForm);
  }

  saveTransactionByOrderAfterPayMintyWallet(dataForm) {
    return api.post('/order/save-transaction-by-order-after-pay-minty-wallet', dataForm);
  }

  retryOrderWalletNotEnough(dataForm) {
    return api.post('/retry-order-wallet-not-enough', dataForm);
  }
}

export default new OrderService();
