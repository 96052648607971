<template>
  <div class="h-100" v-if="isShowLayout">
  <!-- <sidenav :custom_class="color" :class="[isRTL ? 'fixed-end' : 'fixed-start']" v-if="showSidenav" /> -->
  <main class="main-content position-relative overflow-x-hidden">
    <!-- nav -->
    <!-- <navbar :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
      :color="isAbsolute ? 'text-white opacity-8' : ''" :minNav="navbarMinimize" v-if="showNavbar" /> -->
    <router-view />
  </main>
  </div>
</template>
<script>
// import Sidenav from "./common/Sidenav";
// import Navbar from "@/common/Navbars/Navbar.vue";
import {mapState, mapActions } from "vuex";
// import $ from "jquery";
import axios from "axios";
import { localStorageName } from '@/config/utils';

export default {
  name: "App",
  components: {
    // Sidenav,
    // Navbar
  },
  data() {
    return {
      isShowLayout: false,
    }
  },
  mounted() {
    // if (window.innerWidth < 1200) {
    //   $(".icon-close-sidenav-main").show();
    // } else {
    //   $(".icon-close-sidenav-main").hide();
    // }

    if (['/404', '/page-expired'].includes(window.location.pathname)) {
      this.isShowLayout = true;
    } else {
      var _this = this;
      setTimeout(function() {
        const merchantCode = localStorage.getItem('MERCHANT_CODE', '');
        const branchCode = localStorage.getItem('BRANCH_CODE', '');

        _this.checkMerchantAndBranch(merchantCode, branchCode);
      }, 100)
    }
  },
  methods: {
    ...mapActions(["setColor", "setMerchantCode", "setBranchCode", "setError"]),
    checkMerchantAndBranch(merchantCode, branchCode) {
      this.isShowLayout = false;
      axios.post(process.env.VUE_APP_BASE_URL + '/check-merchant-and-branch', 
        {
          branch_code: branchCode
        },
        {
          headers: {
            "MerchantCode": merchantCode
          }
        }
      )
        .then(async (response) => {
          var dataResponse = response.data;
          if (dataResponse.result.isSuccess) {
            await this.setColor(dataResponse.data.moo_color ?? '#96b7d8');
            await this.setMerchantCode(merchantCode);
            await this.setBranchCode(branchCode);
            localStorage.setItem(localStorageName('MERCHANT_ID'), dataResponse.data.merchant_id ?? 0);
            localStorage.setItem(localStorageName('MERCHANT_BRAND_NAME'), dataResponse.data.merchant_brand_name ?? '');
            localStorage.setItem(localStorageName('BRANCH_INFO'), dataResponse.data.branch_info ? JSON.stringify(dataResponse.data.branch_info) : '');
            localStorage.setItem(localStorageName('MERCHANT_LOGO'), dataResponse.data.merchant_logo ?? '');
            localStorage.setItem(localStorageName('MERCHANT_LOGO'), dataResponse.data.merchant_logo ?? '');
            localStorage.setItem(localStorageName('DISPLAY_SPECIAL_REQUEST_IN_POS'), dataResponse.data.data_merchant_setting.display_special_request_in_pos ?? "");
            this.isShowLayout = true;
          }
        },
          (error) => {
            this.setError(error.response.data.result ?? {});
            this.isShowLayout = true;
              this.$router.push({ name: 'Error404' });
          }
        );
    }
  },
  computed: {
    ...mapState([
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton"
    ]),
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";

    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    }
  }
};
</script>

<style scoped>
.main-content {
  background: #ffffff;
  overflow-y: auto !important;
  min-height: 100%;
  max-height: 100%;
  height: 100%;
}
</style>
